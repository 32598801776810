* {
  box-sizing: border-box;
}

body {
  overflow-x: hidden;
}

html, body {
  padding: 0;
  margin: 0;
}

.container {
  display: flex;
  overflow: hidden;
  height: 100vh;
  position: relative;
  width: 100%;
  max-width: 100%;
  padding: 0;
}

/* https://stackoverflow.com/a/47818418 */
@supports (-webkit-touch-callout: none) {
  .container {
    height: -webkit-fill-available;
  }
}

.left, .right {
  overflow: auto;
  height: auto;
}

.right {
  flex: 1;
}

.left {
  padding: 5px;
}

form {
  margin: 5px;
}

@media screen and (min-width: 800px) {
  .sidebar-toggle {
    display: none;
  }
}

.sidebar-form {
  padding: 15px;
}
form {
  margin: 0;
}

.sidebar-form.disabled {
  background-color: rgba(0,0,0,0.3);
  pointer-events: none;
  opacity: .5;
}

.sidebar-closed .toggle-button {
  height: 100%;
}
.sidebar-open .toggle-button {
  width: 100%;
  height: 40px;
}

.strike {
  display: block;
  text-align: center;
  overflow: hidden;
  white-space: nowrap; 
}

.strike > span {
  position: relative;
  display: inline-block;
}

.strike > span:before,
.strike > span:after {
  content: "";
  position: absolute;
  top: 50%;
  width: 9999px;
  height: 1px;
  background: gray;
}

.strike > span:before {
  right: 100%;
  margin-right: 15px;
}

.strike > span:after {
  left: 100%;
  margin-left: 15px;
}

.inline-checkbox {
  display: inline;
  margin-left: 8px;
}

.sidebar-form>div>label {
  font-size: 20px;
  font-weight: 500;
}

.form-label {
  margin-bottom: 0;
}

.display-status {
  padding: 15px;
  font-size: 20px;
  font-weight: 200;
}
